import * as React from "react"
import styled from "@emotion/styled";
import {Link } from "gatsby"

const PostContainer = styled.div`
  border: 1px solid;
  border-radius: 10px;
  padding: 0.5em;
  margin-left: 1em;
  margin-right: 1em;
`
const PostUp = styled.div`s
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Post = ({post}) => {
    return <PostContainer>
        <PostUp>
        <h2>
            <Link
                style={{
                    textDecoration: "none"
                }}
                to={`/post/${post.slug}`}>
                {post.frontmatter.title}
            </Link>
        </h2>
        <p>
            {post.frontmatter.date}
        </p>
        </PostUp>
        <p>{post.frontmatter.description}</p>
    </PostContainer>
}

export default Post