import * as React from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import styled from "@emotion/styled"
import Post from '../components/post-preview'
import Experience from "../components/experience"

const TechnoItem = styled.div`
  border: 0.1em solid;
  border-radius: 1.5em;
  justify-content: center;
  padding: 0.6em;
  color: #0288d1;
`

const TechnoContainer = styled.div`
  display: flex;
  justify-content: center;
  justify-items: center;
  gap: 8px;
  flex-wrap: wrap;
  font-weight: 600;
`

const MainContainer = styled.div`
  text-align: center;
  display: block;
  & > * {
    margin: 2em 0 2em 0;
  }
`

const MyName = styled.span`
  color: #00B29C;
  font-weight: 800;
  font-size: 1.2em;
`

const technoligies = [
  "Jax",
  "Kubernetes",
  "Rust",
  "Python",
  "Ray",
  "Distributed System",
  "C/C++",
  "Pytorch",
  "Go",
  "Docker",
  "Database",
  "gRPC",
  "AWS",
  "GCP",
  "Scaleway"
];

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMdx.nodes

  return (
    <>
      <Seo title={siteTitle} />
      <MainContainer>
        <h1>
          Hi my name is <MyName>Armand Picard</MyName>
        </h1>
        <h2>
          I'm a computer science student passionate about Machine Learning, HPC and Cloud native technologies.
        </h2>
        <h2>
          Technologies I like to play with
        </h2>
        <TechnoContainer>
          {
            technoligies.map((tech) => (
              <TechnoItem>
                {tech}
              </TechnoItem>
            ))
          }
        </TechnoContainer>
        {/* <div>
          <Experience
            title="MLOps/Platform Engineer Intern"
            company="Instadeep"
            date="3d/week 09-2022 to 02-2023"
            description=""
          />
          <Experience
            title="DevOps Engineer Intern"
            company="Instadeep"
            date="full time 04-2022 to 08-2022"
            description=""
          />
        </div> */}

        {/* {posts.map(post => (
          <Post post={post}/>
        ))} */}
      </MainContainer>
    </>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {frontmatter: {status: {eq: "public"}} }
    ) {
      nodes {
        excerpt
        slug
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          status
        }
      }
    }
  }
`
