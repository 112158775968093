import styled from "@emotion/styled";
import * as React from "react"

const Container = styled.div`
    text-align: left;
    padding: 0em 1em 0em 1em;
    border: solid blue 2px;
    margin: 2em;
`

const Title = styled.h2``

const SubTitleContainer = styled.div`
    border: 0;
    display: flex;
    justify-content: space-between;
`

const SubTitle = styled.p``

const Experience = ({title, company, description, date}) => {
    return <Container>
        <Title>{title}</Title>
        <SubTitleContainer>
            <SubTitle>{company}</SubTitle>
            <SubTitle>{date}</SubTitle>
        </SubTitleContainer>
        <p>{description}</p>
    </Container>
}

export default Experience;